@import '../../../../scss/theme-bootstrap';

.complementary-benefit {
  text-align: center;
  @include breakpoint($landscape-up) {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  &__header {
    font-family: $font--malone;
    margin-bottom: 30px;
    font-size: 24px;
    line-height: get-line-height(24px, 24px);
    letter-spacing: 0.01em;
  }
  &__copy {
    font-family: $font--lars;
    font-size: 14px;
    line-height: get-line-height(14px, 17px);
  }
}
